import http from './httpService';
// import { apiUrl } from '../config.json';

const apiUrl = process.env.REACT_APP_API_URL;

const apiEndpoint = apiUrl + '/users';

export function register(user) {
  return http.post(apiEndpoint, {
    name: user.name,
    email: user.email,
    password: user.password,
    zip: user.zip,
    phone: user.phone,
    marketing: user.marketing,
  });
}
